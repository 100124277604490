.user-image[data-v-52fa09d3] {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  white-space: nowrap;
}
.user-image[data-v-52fa09d3] .guigu_diary_avatar {
  width: 24px;
  height: 24px;
}
