.detail-width[data-v-e75d9d21] {
  position: relative;
  flex: 1;
  overflow: auto;
}
.detail-container[data-v-e75d9d21] {
  position: relative;
  border-radius: 8px;
  margin-right: 8px;
  padding: 8px;
  padding-top: 0px;
  padding-left: 0px;
}
.detail-container[data-v-e75d9d21]:hover {
  background: #f0f2f5;
}
.detail-container:hover .to_detail[data-v-e75d9d21] {
  opacity: 1;
}
.detail-container .to_detail[data-v-e75d9d21] {
  position: absolute;
  right: 10px;
  opacity: var(--e75d9d21-detailButtonOpacity);
}
.detail-container .detail-item[data-v-e75d9d21] {
  margin: 4px 0;
}
.detail-container .detail-item .label[data-v-e75d9d21] {
  margin-right: 8px;
  font-weight: bolder;
}
.detail-container .detail-item .label .fullname[data-v-e75d9d21] {
  vertical-align: bottom;
  line-height: 25px;
}
.detail-container .detail-item .label .record_attendance_type[data-v-e75d9d21] {
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 8px;
  color: #fff;
  font-size: 10px;
  padding-bottom: 2px;
  padding-top: 2px;
  border-radius: 8px;
  background: #373b415e;
}
.detail-container .detail-item .value[data-v-e75d9d21] {
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
}
.detail-container .add-weather-item[data-v-e75d9d21] {
  margin: 4px 0;
  display: flex;
}
.detail-container .add-weather-item .label[data-v-e75d9d21] {
  margin-right: 8px;
  font-weight: bolder;
}
.detail-container .add-weather-item .space[data-v-e75d9d21] {
  margin-left: 20px;
  /* 适当调整空白的大小 */
}
.table-container[data-v-e75d9d21] {
  white-space: normal;
}
.img-container[data-v-e75d9d21] {
  margin-bottom: 8px;
  overflow-x: scroll;
  white-space: nowrap;
}
.img-item[data-v-e75d9d21] {
  display: inline-flex;
  width: 100px;
  height: 100px;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  box-shadow: 4px 4px 15px #666;
}
.img[data-v-e75d9d21] {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
